import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MESSAGE_TYPES } from 'src/app/infrastructure/message.types';
import { NavigatedMessageData } from 'src/app/infrastructure/message.data.types';
import { Messenger } from '../messenger.module/messenger';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  private history: string[] = [];
  private currentUrl: string = '';
  private isPopStateNavigation: boolean = false;

  constructor(private router: Router,
    private messenger: Messenger) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.currentUrl = urlAfterRedirects;
        if (this.isPopStateNavigation){
          this.history.pop();
        }else{
          this.history.push(urlAfterRedirects);
        }
        
        this.messenger.Send({
          messageType: MESSAGE_TYPES.NAVIGATED,
          messageData: new NavigatedMessageData(false)
        });
      });

      this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.isPopStateNavigation = true;
        }
        else{
          this.isPopStateNavigation = false;
        }
      });
  }


  getCurrentUrl(): string {
    return this.currentUrl;
  }
  
  getPreviousUrl(): string {
    return this.history[this.history.length - 2] || null;
  }

  popLastUrlFromHistory(): void{
    this.history.pop();
  }
  
  getHistory(): string[] {
    return this.history;

  }

  canGoBack():boolean{
    return this.getPreviousUrl()?.length > 0;
  }


  
  clearHistory(): void {
    this.history = [];
  }
}
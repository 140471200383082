import { Injectable, Inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { ParsedUrl } from "src/app/services/url.helpers.module/model/parsed.url";
import { UrlHelpersService } from "./services/url.helpers.module/url.helpers.service";
import { AuthorizationService } from "./services/authorization.module/authorization.service";
import { ApplicationStateService } from "./services/application.state.module/application.state.service";
import { StatusCodeService } from "./services/status.code.module/status.code.service";
import { LoggingService } from "./services/logging.module/logging.service";
import { Location } from "@angular/common";

@Injectable({providedIn: 'root'})
export class PlacebuzzGuard  {
    constructor(
        private platformService: PlatformHelpersService,
        private authorizationService: AuthorizationService,
        private urlHelpersService: UrlHelpersService,
        private applicationStateService: ApplicationStateService,
        private statusCodeService: StatusCodeService,
        private router: Router,
        private location: Location,
        private loggingService: LoggingService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return new Promise(resolve => {
            if (this.platformService.IsBrowserPlatform && this.platformService.forceServerSideNavigation) {
                this.loggingService.LogToDevConsole("Forcing server side navigation");
                var navigateTo = window.location.protocol + "//" + window.location.host + state.url;
                window.location.href = navigateTo;
                resolve(false);
                return;
            }

            this.urlHelpersService.RemovePopupVisible();
            
            if (this.platformService.IsBrowserPlatform){

                //TOKEN PRESENT
                if (this.doesRouteHaveToken(route)){
                    this.loggingService.LogToDevConsole("Route has token");

                    if (this.authorizationService.isLoggedIn){
                        this.applicationStateService.LogoutAndClearMe();
                    }

                    if (this.DoNotLoginWithToken(route)){
                        //For some pages, even tokens should not let people login
                        resolve(true);
                        return;
                    }

                    this.authorizationService.LoginUserWithToken(route.queryParams["token"]).then(result => {
                        if (result.authorizationProperties && result.authorizationProperties.authorizationCodeAccessToken) {
                            this.loggingService.LogToDevConsole("Token is valid, refreshing ME from server");
                            this.applicationStateService.RefreshMeFromServer();
                            resolve(true);
                        }
                        else{
                            this.loggingService.LogToDevConsole("Token is invalid");
                            if (!this.CanOnlyAccessWithValidToken(route)){
                                this.loggingService.LogToDevConsole("Route is not guarded and can be accessed without token");
                                resolve(true);
                                return;
                            }
                            else{
                                this.loggingService.LogToDevConsole("Route is guarded - redirect to login");
                                var url = this.urlHelpersService.GetUrlStringFromParsedUrl({
                                    path: route.url.map(u => u.path),
                                    queryObject: route.queryParams,
                                });

                                url = this.urlHelpersService.RemoveQueriesFromPath(url, ["token"]);
                                this.applicationStateService.StorePostLoginReturnUrl(url);
    
                                var expiredRoute: ParsedUrl = {
                                    path: ['/logon'],
                                    queryObject: null
                                };

                               
                                this.router.navigate(expiredRoute.path, { queryParams: expiredRoute.queryObject });
                                
                                resolve(false);
                            }
                        }
                    });

                }
                //NO TOKEN
                else
                {
                    this.loggingService.LogToDevConsole("Route does not have token");

                    if (this.authorizationService.isLoggedIn)
                    {
                        this.loggingService.LogToDevConsole("User is logged in - Refresh ME");
                        if (!this.applicationStateService.me){
                            this.applicationStateService.RefreshMeFromServer();
                        }
                        
                        resolve(true);
                    }
                    else{
                        var result = this.CanNavigateWithClientCredentials(route) || this.AllowAnonymous(route);
                        
                        if (!result) {
                            this.applicationStateService.StorePostLoginReturnUrl(state.url);
                            this.statusCodeService.SetStatusCode(302);
                            this.router.navigate(['/logon']);
                        }
                        
                        resolve(result);
                    }
                }      
            }
            //SERVER SIDE
            else{
                resolve(true);
            }
        });
    }

    private doesRouteHaveToken(route: ActivatedRouteSnapshot):boolean{
        if (route.queryParams && route.queryParams["token"]){
            return true;
        }
        return false;
    }

    private CanNavigateWithClientCredentials(route: ActivatedRouteSnapshot) {
        return route.data && route.data["allowClientCredentials"];
    }

    private CanOnlyAccessWithValidToken(route: ActivatedRouteSnapshot) {
        return route.data  && route.data["canOnlyAccessWithValidToken"];
    }

    private AllowAnonymous(route: ActivatedRouteSnapshot) {
        return route.data  && route.data["allowAnonymous"];
    }

    private DoNotLoginWithToken(route: ActivatedRouteSnapshot) {
        return route.data  && route.data["doNotLoginWithToken"];
    }
    private RequiresToken(route: ActivatedRouteSnapshot) {
        return route.data  && route.data["requiresToken"];
    }

    

    

    
}